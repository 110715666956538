import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { createTheme } from '@mui/material/styles';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "./firebase";
import './NavBarProfile.css'
import Avatar from "@mui/material/Avatar";
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });

// const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Dashboard', 'Settings'];

export default function NavBarProfile() {

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    console.log(event);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSettingClick = (event) => {
    setAnchorElUser(null);
    console.log(event);
    window.location.href = '/'+event.target.innerText;
  };
  
  const [user, loading, error] = useAuthState(auth);
  if (loading) {
    return (<div>
      {/* Loading... */}
    </div>)
  }
  //console.log(user);
  if (!user) {
    return (
      <Button color="inherit" onClick={event => window.location.href = '/login'}>Login</Button>
    )
  }
  return (
    <div>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={user?.displayName} src={user?.photoURL} />            
            {/* <Typography color="white">{user?.displayName}</Typography> */}
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem key={setting} onClick={handleSettingClick}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
          <MenuItem key='logout' onClick={logout}>
            <Typography textAlign="center">logout</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </div>
  );
}