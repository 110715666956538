import './Dashboard.css';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [docList, setDocList] = useState([]);
  const navigate = useNavigate();
  const fetchDocs = async () => {
    try {
      const q = query(collection(db, "posts"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      let tempDocList=[];
      setDocList([]);
      for (let i = 0; i < doc.docs.length; i++) {
        //console.log(doc.docs[i]);
        let td = {
          id:doc.docs[i].id,
          data: doc.docs[i].data()
        }
        
        tempDocList.push(td);
      }
      
      // console.log(tempDocList);
      setDocList(tempDocList);
    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };
  
  //console.log(user);
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchDocs();
  }, [user, loading]);

  const docListItems = docList.map((todo) =>
  <li key={todo.id}>
    <a href={'/snippet/view/' + todo.id}>{todo.data.title}</a>
  </li>
);

  return (
    <div >
       <div className="dashboard__sidebar">
        Logged in as
        <div >
            <img className="dashboard__img" src={user?.photoURL}></img>
        </div>
         <div>{user?.displayName}</div>
         <div>{user?.email}</div>
         <h1>Projects</h1>
         <ul>{docListItems}</ul>
       </div>
     </div>
  );
}
export default Dashboard;
