import * as React from 'react';
import { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import NavBarProfile from './NavBarProfile';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const getCurrentPathWithoutLastPart = () => {

  return window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'))
}

function NavBar() {
  const [user, loading, error] = useAuthState(auth);
  const [cookies, setCookie, removeCookie] = useCookies(['appdash-proj']);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [appList, setAppList] = useState([]);
  const { projId } = useParams();
  const [curProjId, setCurProjId] = React.useState(cookies.projId);


  const fetchAppList = async () => {
    try {

      const appCollection = await getDocs(collection(db, "projects"));

      let tempAppList = [];
      setAppList([]);
      for (let i = 0; i < appCollection.docs.length; i++) {

        let td = {
          id: appCollection.docs[i].id,
          data: appCollection.docs[i].data()
        }
        tempAppList.push(td);
      }

      setAppList(tempAppList);

      if(projId === undefined && appList.length > 0){
        console.log(projId)
        console.log(appList[0].id)
        setCurProjId(appList[0].id);
      }

    } catch (err) {
      console.error(err);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    console.log(event);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSettingClick = (event) => {
    setAnchorElUser(null);
    console.log(event);

    window.location.href = '/' + event.target.innerText;
  };

  

  const handleProjectSelectChange = (event) => {    
    let baseUrl = getCurrentPathWithoutLastPart();    
    setCurProjId(event.target.value);
    setCookie('projId', event.target.value, { path: '/' });

    if(baseUrl === '/app/logs')
    {
      window.location.href = `${baseUrl}/${event.target.value}`;
    }
  };

  useEffect(() => {
    if (loading) return;
    //if (!user) return navigate("/");
    fetchAppList();
  }, [user, loading]);

  const projectListItems = appList.map((app) =>
    <MenuItem key={app.id} value={app.id}>
      {app.data.name}
    </MenuItem>
  );

  

  if (!user) {
    return (
      <ThemeProvider theme={darkTheme}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <Button
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={event => window.location.href = '/dashboard'} >
                <img src={require('./img/logo32.png')} />
                <Typography component="div">AppDash</Typography>
              </Button>
              <Box component="div" sx={{ flexGrow: 1 }}>

              </Box>
              <NavBarProfile />
            </Toolbar>
          </AppBar>
        </Box>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Button
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={event => window.location.href = '/dashboard'} >
              <img src={require('./img/logo32.png')} />
              <Typography component="div">AppDash</Typography>
            </Button>

            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={curProjId !== undefined ? curProjId : appList.length > 0} label="App" onChange={handleProjectSelectChange}>
              {projectListItems}
            </Select>
            <Box component="div" sx={{ flexGrow: 1 }}>
            <Button variant="h1" key='apps' onClick={() => window.location.href = `/apps`} >Apps</Button>
            <Button variant="h1" key='logs' onClick={() => window.location.href = `/app/logs/${curProjId}`} >Logs</Button>
            </Box>
            <NavBarProfile />
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
export default NavBar;
