import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    GithubAuthProvider,
    getAuth,
    signInWithRedirect,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    setDoc,
    doc
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD5sGu5TvygYyYe-tIy2Cl2nWvzBfkW8gU",
    authDomain: "virtex-appdash.firebaseapp.com",
    projectId: "virtex-appdash",
    storageBucket: "virtex-appdash.appspot.com",
    messagingSenderId: "356536211589",
    appId: "1:356536211589:web:8c655eb102e7882f76f1ec",
    measurementId: "G-ZZ7BX6QYEY"
  };


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();
const githubProvider = new GithubAuthProvider();

const signInWithGoogle = async () => {
    try {
        const res = await signInWithRedirect(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        analytics.logEvent('signin_google');
        if (docs.docs.length === 0) {
            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
                img: user.photoURL
            });
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};


const signInWithGithub = async () => {
    try {
       await signInWithRedirect(auth, githubProvider).then((result) => {
    // This gives you a GitHub Access Token. You can use it to access the GitHub API.
    // const credential = GithubAuthProvider.credentialFromResult(result);
    // const token = credential.accessToken;

    // // The signed-in user info.
    // const user = result.user;
    //console.log(user);
    // ...
  }).catch((error) => {
    // Handle Errors here.
    // const errorCode = error.code;
    // const errorMessage = error.message;
    // // The email of the user's account used.
    // const email = error.customData.email;
    // // The AuthCredential type that was used.
    // const credential = GithubAuthProvider.credentialFromError(error);
    // ...
  });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const googleRedirectResult = async (user) => {
    if (user) {
        //console.log(user);
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                name: user.displayName,
                authProvider: user.providerData[0].providerId,
                email: user.email,
                img: user.photoURL
            });
        }
    }
}


const sendPost = async (user, title, text,type) => {
    if (user) {
        //console.log(user);
     var res = await addDoc(collection(db, "posts"), {
            uid: user.uid,
            title: title,
            text: text,
            type: type
        });
        console.log(res);
    }
}

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const logout = () => {
    signOut(auth);
};
export {
    auth,
    db,
    signInWithGoogle,
    signInWithGithub,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    googleRedirectResult,
    sendPasswordReset,
    logout,
    sendPost
};