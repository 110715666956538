import './App.css';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import AnalyticsSink from "./AnalyticsSink";
import Reset from "./Reset";
import Dashboard from "./Dashboard";
import ProjectsView from "./ProjectsView";
import AppLogs from "./AppLogs";
import NavBar from './NavBar';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<div><NavBar /></div>} />
          <Route exact path="/login" element={<div><NavBar /><Login /></div>} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/dashboard" element={<div><NavBar /><Dashboard /></div>} />
          <Route exact path="/apps" element={<div><NavBar /><ProjectsView /></div>} />
          <Route exact path="/app/logs/:projId" element={<div><NavBar /><AppLogs /></div>} />
          <Route exact path="/app/analytics/log/:projId/:evtId" element={<div><AnalyticsSink /></div>} />
                <Route render={() => <div>Not Found</div>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
