import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "./firebase";
import { getApp } from "firebase/app";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import "./AppLogs.css";
import AppLogEntry from "./AppLogEntry"

const app = getApp();
const analytics = getAnalytics(app);

function AppLogs() {
  const [user, loading, error] = useAuthState(auth);
  const [appInfo, setAppInfo] = useState([]);
  const [docList, setDocList] = useState([]);
  const navigate = useNavigate();
  const { projId } = useParams();
  
  const fetchLogsForApp = async () => {
    try {
      logEvent(analytics, 'get_logs');

      logEvent(analytics, 'pzb_session',{
        appid:projId,
        env:"prod",
        platform:"appstore",
        os:"ios"
      });

      const appInfo = await getDoc(doc(db, "projects", projId));
      setAppInfo(appInfo.data());

      const logDocs = await getDocs(collection(db, "projects", projId, "logs"));

      let tempDocList = [];
      setDocList([]);
      for (let i = 0; i < logDocs.docs.length; i++) {
        
        let td = {
          id: logDocs.docs[i].id,
          data: logDocs.docs[i].data()
        }
        tempDocList.push(td);
      }
      tempDocList.sort((a,b) => b.data.time.seconds - a.data.time.seconds);
      setDocList(tempDocList);

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };

  

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    if(error){
      alert(error);
    }
    fetchLogsForApp();
  }, [user, loading]);
  
      const logsListItems = docList.map((entry) =>
      <AppLogEntry key={entry.id} appInfo={appInfo} entry={entry} />
  );

  return (
    <div >
      <div className="logs__container">
        <h1>App Logs</h1>
        <div className="loglist-container">{logsListItems}</div>
      </div>
    </div>
  );
}
export default AppLogs;
