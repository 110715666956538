import React, { useEffect, useState } from "react";
import "./AppLogs.css";
import "./AppLogEntry.css"
import { bgcolor } from "@mui/system";

/**
 * 
 * @param {*} param0 
 * @returns 
 */
function AppLogEntry({ appInfo, entry }) {
    const [toggleState, setToggleState] = useState(false);

    /**
     * Toggle the Stack Trace
     */
    const toggleStackTrace = () => {
        setToggleState(!toggleState);
    };
    console.log(entry);

    const getPlatformIcon = (os) =>{
        return (os == "Android") ? "https://freeiconshop.com/wp-content/uploads/edd/android-flat.png" : "https://cdn6.aptoide.com/imgs/f/8/6/f86e91421358b61486a37ed320af2161_icon.png";
    }

    const numToHexColorCode = (num) => {
        let n = (num * 0xfffff * 100000).toString(16);
        return '#' + n.slice(0, 6);
      };

    const getVersionColour=(versionString)=>{
        let vArray = versionString.split('.');
        
        let randVersion = 1;
        for(var i = 0; i< vArray.length; i++){
            randVersion = randVersion + Number(vArray[i]);
        }
    
        let fx = numToHexColorCode(randVersion)
    
        return fx;
    }

    return (
        <div className="log-container">
            <button onClick={toggleStackTrace} key={entry.id} className="log-container-button" >

                <table className="log-container-table">
                    <tr>
                        <td className="log-icon-container">
                            <img className="app-icon-small" src={getPlatformIcon(entry.data.os)} />
                        </td>
                        <td className="log-version-container">
                            <span className="log-version" style={{backgroundColor: getVersionColour(entry.data.version)}}>{entry.data.version}</span>
                        </td>
                        <td className="log-time-container">
                            <span className="log-time"> {new Date(entry.data.time.seconds * 1000).toLocaleString()}</span>
                        </td>
                        <td >
                            <i>{entry.data.msg}</i>
                        </td>
                    </tr>
                </table>
            </button>
            {toggleState ? (
                <div className="log-container-stacktrace">
                    <p className="log-stack">{entry.data.stack}</p>
                </div>
            ) : ("")}
        </div>
    );
}
export default AppLogEntry;
