import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "./firebase";
import { getApp } from "firebase/app";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import "./AnalyticsSink.css";
import queryString from "query-string"

const app = getApp();
const analytics = getAnalytics(app);
let cnt =0; 
function AnalyticsSink() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const { projId, evtId } = useParams();
  const [qInfo, setQInfo] = useState([]);


useEffect(()=>{
  const queryParams = queryString.parse(window.location.search)
  
  // Make sure this only fires once
  if(cnt === 0)
  {
    let info = {...queryParams};
    info.appId = projId;
    console.log(info);
    cnt++;
      logEvent(analytics, evtId,info);
      setQInfo(queryParams);
    }
}, []);

  return (
    <div >
      <div className="logs__container">
        <h1>Q Info</h1>
        <div>
        All query params <pre>{JSON.stringify(qInfo, null, 2)}</pre></div>
      </div>
    </div>
  );
}
export default AnalyticsSink;
