import React, { useEffect, useState } from "react";
import "./ProjectEntry.css"

/**
 * 
 * @param {*} param0 
 * @returns 
 */
function ProjectEntry({ appInfo }) {
    const [toggleState, setToggleState] = useState(false);

    /**
     * Toggle the Stack Trace
     */
    const onProjectButtonClicked = () => {
        console.log(`Button for ${appInfo.data.name} has been clicked`)
    };
    

    const titleStyle = { width: "20px" };

    return (
        <div className="project-container">
            <button onClick={onProjectButtonClicked} key={appInfo.id} className="project-container-button" >

                <table className="project-container-table">
                    <tr>
                        <td sx={titleStyle}>
                            <img className="project-app-icon" src={appInfo.data.iconPath} />
                        </td>
                        <td sx={titleStyle}>
                            <strong>{appInfo.data.name}</strong>
                        </td>
                        <td sx={titleStyle}>
                            
                        </td>
                        <td >
                            
                        </td>
                        <td>
                            
                        </td>
                    </tr>
                </table>
            </button>
        </div>
    );
}
export default ProjectEntry;
