import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./AppLogs.css";
import { auth, db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import ProjectEntry from "./ProjectEntry"

function ProjectsView() {
  const [user, loading, error] = useAuthState(auth);
  const [appList, setAppList] = useState([]);
  const navigate = useNavigate();

  const fetchAppList = async () => {
    try {

      const appCollection = await getDocs(collection(db, "projects"));

      let tempAppList = [];
      setAppList([]);
      for (let i = 0; i < appCollection.docs.length; i++) {
        console.log(appCollection.docs[i].data());
        let td = {
          id: appCollection.docs[i].id,
          data: appCollection.docs[i].data()
        }
        tempAppList.push(td);
      }
      
      setAppList(tempAppList);
      
    } catch (err) {
      console.error(err);
    }
  };

  

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchAppList();
  }, [user, loading]);
  
      const logsListItems = appList.map((app) =>
      <ProjectEntry key={app.id} appInfo={app}/>
  );

  return (
    <div >
      <div className="logs__container">
        <h1>Projects Logs</h1>
        <div className="loglist-container">{logsListItems}</div>
      </div>
    </div>
  );
}
export default ProjectsView;
