import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, googleRedirectResult, signInWithGoogle, signInWithGithub } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import {  getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import GoogleButton from 'react-google-button'

getRedirectResult(auth)
  .then((result) => {
    //console.log(result);
    if(result){
    // This gives you a Google Access Token. You can use it to access Google APIs.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;

    // The signed-in user info.
    const user = result.user;
    
    googleRedirectResult(user);
    }
  }).catch((error) => {
    // Handle Errors here.
    console.error(error);
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData?.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading]);
  if(loading)
  return(<div>
    <h1>Loading...</h1>
  </div>)
  return (
    <div className="login">
      <div className="login__container">
        Login with a Google Account below
        <GoogleButton  onClick={() => { signInWithGoogle() }}/>
      </div>
    </div>
  );
}
export default Login;